import './bootstrap';
import './address.js';

import SignaturePad from 'signature_pad';
window.SignaturePad = SignaturePad;

/**
 * Parent signature functionality has been commented out
 * If they adapt the API for it, adapt the code so we send (and check) it again
 */
$(document).ready(function () {

    let minor = false;

    // set the signature boxes
    let signaturePad = new SignaturePad($('#canvas')[0]);
//    let signaturePadParent = new SignaturePad($('#canvas-popup')[0]);

    // the erase buttons
    $('#clear').on('click', function () {
        signaturePad.clear();
    });
    $('#clearParent').on('click', function () {
//        signaturePadParent.clear();
    });

    // on load (can be after submit, so the form has data)
    updateAddress();
    updateFullName();
    updateSignatures();
    updateForm(true);
    checkMinor($('#BirthDate').val());

    /**
     * show or hide parental consent
     */
    $('#BirthDate').on('change', function () {
        let date = $(this).val();
        checkMinor(date);

    });

    /**
     * Set the fullname on email adress change (as we know that all name fields are filled by then)
     */
    $('#Email').on('blur', function () {
        updateFullName();
    });

    // enable/disable sections and submit
    $('#sportify-form :input').on('change', function () {
        updateForm();
    });

    // cancel button
    $('#cancel').on('click', function () {
        $('#sportify-form')[0].reset();
    });

    // Address lookup functionality
    $('#Zipcode').blur(updateAddress);
    $('#HouseNumber').blur(updateAddress);
    $('#HouseNumberExtension').blur(updateAddress);

    // date functionality
    var day = $('#day');
    var month = $('#month');
    var year = $('#year');
    day.blur(updateBirthDate);
    month.blur(updateBirthDate);
    year.blur(updateBirthDate);
    day.keyup(function () {
        if (day.val() > 31) {
            day.val(31);
        }
        checkGoNext(day);
    });
    month.keyup(function () {
        if (month.val() > 12) {
            month.val(12);
        }
        checkGoNext(month)
    });
    year.keyup(function () {
        checkGoNext(year)
    });

    // on submit
    $('#sportify-form').submit(function (e) {

//        let minor = eval($('#minor').val());
        if (signaturePad.isEmpty()) {
            e.preventDefault();
            $('#signatureWarning').text('Handtekening verplicht!');
//        } else if (minor && signaturePadParent.isEmpty()) {
//            e.preventDefault();
//            $('#parentSignatureWarning').text('Handtekening verplicht!');
//            $('#collapseParentButton').click();
        } else {
            $('#signature').val(signaturePad.toDataURL());
        }
    });

    $('#headingPersonData').on('click', function () {
        $('.alert-success').remove();
    });
    $('#headingContactData').on('click', function () {
        updateForm();
    });
    $('#headingParentData').on('click', function () {
        updateForm();
    });
    $('#headingFinalData').on('click', function () {
        updateForm();
    });

    /**
     * Update the address preview and set hidden address fields
     */
    function updateAddress() {
        let zip = $('#Zipcode').val();
        let number = $('#HouseNumber').val();
        let preview = $('#addressPreview');

        if (zip === '' || number === '') {
            return;
        }

        window.addressLookup.lookUpAddress(zip, number)
            .then(function (data) {
                if (typeof data != 'undefined') {
                    let address = data.Address + ' ' + data.HouseNumber + ' ' + $('#HouseNumberExtension').val() + '<br />';
                    address += data.Zipcode + ' ' + data.City;
                    $('#Address').val(data.Address);
                    $('#City').val(data.City);
                    $('#Zipcode').val(data.Zipcode);
                    preview.html(address);
                } else {
                    preview.html('');
                    $('#Address').val(null);
                    $('#City').val(null);
                }
                updateForm();
            })
            .catch(function (error) {
                preview.html('');
                $('#Address').val(null);
                $('#City').val(null);
                updateForm();
            });
    }

    /**
     * Set the BirthDate field based on the day month year
     */
    function updateBirthDate() {
        var birthDay = $('#BirthDate');
        var dayStr = ('00' + $('#day').val()).slice(-2);
        var monthStr = ('00' + $('#month').val()).slice(-2);
        var yearStr = $('#year').val();
        if (dayStr && monthStr && yearStr) {
            birthDay.val(yearStr + '-' + monthStr + '-' + dayStr);
            birthDay.trigger('change');
        }
    }

    /**
     * Check if we go to next field, for date purprose
     * @param element
     */
    function checkGoNext(element) {
        var digits = element.val().length;
        if (element === day) {
            if (digits === 2) {
                month.focus();
            }
        } else if (element === month) {
            if (digits === 2) {
                year.focus();
            }
        } else if (element === year) {
            if (digits === 4) {
                year.blur();
            }
        }
    }

    /**
     * Updates the full name for the parental consent
     */
    function updateFullName() {
        let parts = [
            $('#FirstName').val(),
            $('#MiddleName').val(),
            $('#LastName').val(),
        ];
        parts = $.grep(parts, n => n == 0 || n);
        $('#fullName').text(parts.join(' '));
    }

    /**
     * Rudimentary check, as we can still get february the 31st
     * @param date
     * @returns {boolean}
     */
    function isValidDate(date) {
        let arr = date.split('-');
        if (arr[0] < 1900 || arr[0] > 2050) {
            return false;
        }
        if (arr[1] > 12 || arr[1] < 1) {
            return false;
        }
        if (arr[2] > 31 || arr[2] < 1) {
            return false;
        }
        return true;
    }

    /**
     * based on given date, return the persons age
     * @param birthday
     * @returns {number}
     */
    function getAge(birthday) {
        let month_diff = Date.now() - birthday.getTime();
        let age_dt = new Date(month_diff);
        let  year = age_dt.getUTCFullYear();
        return Math.abs(year-1970);
    }

    /**
     * Check if someone is minor based on (birth)date. Set the minor field.
     * @param date
     */
    function checkMinor(date) {
        if (isValidDate(date)) {
            let birthday = new Date(date);
            let age = getAge(birthday);
            minor = (age < 18);
            if (minor) {
                $('#parentalConsent').show();
                $('#minor').val(true);
                $('#Parent').prop('required', true);
                $('#NameCaregiver').prop('required', true);
                $('#GeneralParent').prop('required', true);
                $('#EmailCaregiver').prop('required', true);
                $('#PhoneCaregiver').prop('required', true);
            } else {
                $('#parentalConsent').hide();
                $('#minor').val(false);
                $('#Parent').removeAttr('required');
                $('#NameCaregiver').removeAttr('required');
                $('#GeneralParent').removeAttr('required');
                $('#EmailCaregiver').removeAttr('required');
                $('#PhoneCaregiver').removeAttr('required');
            }
        }
    }

    /**
     * draw the signatures again, at page reload
     */
    function updateSignatures() {
        let signatureData = $('#signature').val();
//        let parentSignatureData = $('#signatureParent').val();
        if (signatureData) {
            // we want captcha check here but so far i havent been able to implement it well
            signaturePad.fromDataURL(signatureData);
        }
//        if (parentSignatureData) {
//            signaturePadParent.fromDataURL(parentSignatureData);
//        }
    }

    /**
     * Update the section availability and submit button based on required fields
     */
    function updateForm(initialize = false) {
        let minor = eval($('#minor').val());
        // define the next section to enable
        let nextSection = [];
        nextSection['1#collapsePersonButton'] = '2#collapseContactButton';
        if (minor) {
            nextSection['2#collapseContactButton'] = '3#collapseParentButton';
            nextSection['3#collapseParentButton'] = '4#collapseFinalButton';
        } else {
            nextSection['2#collapseContactButton'] = '4#collapseFinalButton';
        }
        // define required fields per section
        let allFields = {};
        allFields['1#collapsePersonButton'] = ['#Location', '#Gender', '#Initials', '#FirstName', '#LastName', '#BirthDate', '#Language'];
        allFields['2#collapseContactButton']  = ['#Email', '#PhonePrivate', '#Zipcode', '#HouseNumber', '#Address', '#City'];
        allFields['4#collapseFinalButton']  = ['#General', '#Safety', '#Privacy'];
        if (minor) {
            allFields['3#collapseParentButton'] = ['#NameCaregiver', '#EmailCaregiver', '#PhoneCaregiver'];
        }
        let allDone = true;
        for (let section in allFields) {
            let done = true;
            let fields = allFields[section];
            for (let index in fields) {
                let field = $(fields[index]);
                $('.after').remove();
                let checkboxChecked = field.attr('type') === 'checkbox' && field.prop('checked');
                let fieldFilled = field.attr('type') !== 'checkbox' && field.val();
                if (!checkboxChecked && !fieldFilled) {
                    done = false;
                    allDone = false;
                    if (!initialize) {
                        var msg = '';
                        if ($.inArray(field.attr('id'), ['Address', 'City']) > -1) {
                            msg = 'Controleer postcode/huisnummer';
                            field.after('<p style="color:red"; class="after">' + msg + '</p>');
                        } else if (field.attr('id') === 'General') {
                            // skip alltogether
                        } else {
                            msg = 'verplicht veld';
                            field.after('<p style="color:red"; class="after">' + msg + '</p>');
                        }
                    }
                    break;
                }
            }
            var next = nextSection[section];
            next = next ? next.slice(1) : null;
            if (done) {
                if (section in nextSection) {
                    $(next).attr('data-bs-toggle', 'collapse');
                }
            } else if (next) {
                $(next).removeAttr('data-bs-toggle');
                break;
            }
        }
        if (allDone) {
            $('#submit').prop('disabled', false);
        } else {
            $('#submit').prop('disabled', true);
        }
    }
});
