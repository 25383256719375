class AddressLookup {

    constructor () {
        this.lookupCache = {};

        this.baseUrl = '/address/find';
    }

    /**
     *
     * @param zipCode
     * @param houseNumber
     * @returns {*}
     */
    lookUpAddress (zipCode, houseNumber) {
        if (window.addressLookup.lookupCache[zipCode] && window.addressLookup.lookupCache[zipCode][houseNumber]) {
            return Promise.resolve(window.addressLookup.lookupCache[zipCode][houseNumber]);
        }

        let url = this.baseUrl + '?zip=' + zipCode + '&house_number=' + houseNumber;

        return fetch(url, {credentials: 'same-origin', headers: {"Accept": "application/json"}})
            .then(function(response) {
                return response.json();
            })
            .then(function(data) {
                if (!window.addressLookup.lookupCache[zipCode]) {
                    window.addressLookup.lookupCache[zipCode] = {};
                }

                window.addressLookup.lookupCache[zipCode][houseNumber] = data.address;
                return window.addressLookup.lookupCache[zipCode][houseNumber];
            });
    };

    /**
     * Function for proper rounding while avoiding JS floating-point rubbish.
     * For more info: http://www.jacklmoore.com/notes/rounding-in-javascript/
     *
     * @param number
     * @param decimals
     * @returns {number}
     */
    static roundFloat(number, decimals) {
        return Number(Math.round(number + 'e' + decimals) + 'e-' + decimals);
    }
}

window.addressLookup = new AddressLookup();
